import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import Accordion from './app/accordion.js';
import Anchor from './app/anchor.js';
import FixedHeader from './app/fixedheader.js';
import HeightLine from './app/heightline.js';
import ResponsiveTable from './app/responsive-table.js';
import Slidebar from './app/slidebar.js';
import Tab from './app/tab.js';
import CurrentNav from './app/current-nav.js';
import buildFormat from './app/format.js';
import OwlCarousel from 'owl.carousel';
import ScrollReveal from "scrollreveal"

import fontAwesome from "font-awesome/scss/font-awesome.scss"
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css"
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css"

class App {
  constructor() {
    this.Utils = new Utils();
    this.Accordion = new Accordion();
    this.Anchor = new Anchor();
    this.FixedHeader = new FixedHeader();
    this.HeightLine = new HeightLine();
    this.ResponsiveTable = new ResponsiveTable();
    this.Slidebar = new Slidebar();
    this.CurrentNav = new CurrentNav();
    this.Tab = new Tab();

    //SPメニューの高さ取得
    function menuHight() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }

      var $smpHeaderHeight = $('.l-header').height();
      var windowHeight = window.innerHeight;
      var winH = windowHeight - $smpHeaderHeight;

      console.log($smpHeaderHeight);

      //動かすターゲットを取得
      var targetSlidebarWrap = $('.c-slidebar-menu'),
        targetSlidebarMenu = $('.c-slidebar__parent'),
        targetSlidebarBtn = $('.c-slidebar-menu__parent');


      //いざ実行(クリックしたら処理を実行する)
      targetSlidebarBtn.on('click', function () {
        $('.c-slidebar-menu').toggleClass('is-active');

      });
    }

    //フッターメニューSPスライド
    function menuSlide() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }
      $('.l-footer__block').on('click', function () {
        $(this).children(".l-footer__menutitle").toggleClass('is-open');
        $(this).children(".l-footer__menulist.is-sub").slideToggle();
      })
    }

    //owlcarousel
    function owlCarousel() {
      var owls = $('.owl-carousel');
      if (owls.length === 0) {
        return false
      }
      //->スライダー
      owls.imagesLoaded(function () {
        $('.c-main-visual__slider').owlCarousel({
          items: 1,
          margin: 0,
          dots: true,
          loop: true,
          nav: false,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<img src="https://gigaplus.makeshop.jp/joylab/assets/images/icon-slider-prev.svg" />', '<img src="https://gigaplus.makeshop.jp/joylab/assets/images/icon-slider-next.svg" />'],
        });
      });
      //->カルーセル
      owls.imagesLoaded(function () {
        $('.c-main-visual__carousel').owlCarousel({
          items: 1,
          margin: 16,
          dots: true,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: false,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<div class="c-main-visual__nav is-left"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div class="c-main-visual__nav is-right"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
          responsive: {
            // breakpoint from 0 up
            0: {
              stagePadding: 32,
            },
            // breakpoint from 750  up
            750: {
              stagePadding: 64,
            },
            // breakpoint from 950 up
            950: {
              stagePadding: 156,
            },
            1400: {
              stagePadding: 312,
            }
          }
        });
      });
      //->カルーセル_ノーマル
      owls.imagesLoaded(function () {
        $('.c-main-visual__carousel.is-normal').owlCarousel({
          items: 1,
          margin: 8,
          dots: true,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: false,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<div class="c-main-visual__nav is-left"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div class="c-main-visual__nav is-right"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
          responsive: {
            // breakpoint from 0 up
            0: {
              stagePadding: 32,
            },
            // breakpoint from 750  up
            750: {
              stagePadding: 64,
            },
            // breakpoint from 950 up
            950: {
              stagePadding: 156,
            },
            1400: {
              stagePadding: 312,
            }
          }
        });
      });
      //->カルーセル_レイヤー
      owls.imagesLoaded(function () {
        $('.c-main-visual-layer__carousel').owlCarousel({
          items: 1,
          margin: 0,
          dots: true,
          loop: true,
          nav: false,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          navText: ['<img src="../assets/images/icon-slider-prev.svg" />', '<img src="../assets/images/icon-slider-next.svg" />'],
        });
      });
      //->カード_カルーセル
      owls.imagesLoaded(function () {
        $('.js-card-slider').owlCarousel({
          dots: false,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          //center: true,
          navText: ['<img src="../../../../../../assets/images/icon-slider-prev.png" />', '<img src="../../../../../../assets/images/icon-slider-next.png" />'],
          responsive: {
            // breakpoint from 0 up
            0: {
              items: 2,
              margin: 8,
            },
            // breakpoint from 750 up
            750: {
              items: 4,
              margin: 36,
            }
          }
        });
      });
    }

    function spanText() {
      // var owls = $('#spanbox');
      // if (owls.length === 0) {
      //   return false
      // }
      // var textbox = document.querySelector('#spanbox');
      // var text = textbox.textContent;
      //
      // textbox.innerHTML = null;
      // text.split('').forEach(function (c) {
      //   textbox.innerHTML += '<span>' + c + '</span>';
      // });
    }

    function scrollReveal() {
      //- スクロールリーバル
      window.sr = ScrollReveal({duration: 600});

      function domEach(items, callback) {
        if (typeof items === "string") {
          var items = $(items);
        }
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          // callback = callback.bind(item)
          callback(i, item);
        }
      }

      sr.reveal(".c-main-visual__image,.l-page-header,.c-block-lead", {
        scale: 1,
        delay: 200,
        opacity: 0,
        duration: 1000,
        origin: 'top',
        distance: '-8px'
      }, 100);
      var blocks01 = $(".c-main-visual__text");
      domEach(blocks01, function (key, item) {
        sr.reveal(item, {scale: 1, duration: 700, delay: 800, origin: 'left', distance: '8px'}, 200);
        sr.reveal(item.children[0], {delay: 900, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[1], {delay: 900, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[2], {delay: 950, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[3], {delay: 1000, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[4], {delay: 1050, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[5], {delay: 1100, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);


        sr.reveal(item.children[6], {delay: 1600, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[7], {delay: 1650, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[8], {delay: 1700, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[9], {delay: 1750, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[10], {delay: 1800, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[11], {delay: 1880, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[12], {delay: 1960, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[13], {delay: 2040, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[14], {delay: 2120, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
        sr.reveal(item.children[15], {delay: 2200, scale: 1, duration: 700, origin: 'left', distance: '8px'}, 300);
      });

    }

    function hoverImg() {
      var $win = $(window).innerWidth();
      if ($win < 750) {
        return false;
      }

      var $hoverTarget = $('.js-products-img-hover');
      if ($hoverTarget.length === 0) {
        return false;
      }

      $hoverTarget.hover(
        function () {
          $(this).addClass('is-active');
        },
        function () {
          $(this).removeClass('is-active');
        }
      )

      // //初期化
      // var imgDefault = $hoverTarget.find(".js-products-img-hover-image").children("img:nth-child(1)");
      // imgDefault.hide(); //最初の画像を非表示
      //
      // //フェードイン処理
      // $hoverTarget.hover(
      //     function(){
      //         var $self = $(this);
      //         var $prarent = $self.find(".js-products-img-hover-image");
      //         $prarent.children("img:nth-child(1)").stop(true).show();
      //         $prarent.children("img:nth-child(2)").stop(true).animate({
      //             opacity: 0
      //         },50,"swing");
      //     },
      //     function(){
      //         var $self = $(this);
      //         var $prarent = $self.find(".js-products-img-hover-image");
      //         $prarent.children("img:nth-child(1)").stop(true).animate({
      //             opacity: 0
      //         },50,"swing",function(){
      //             $(this).css("opacity","1");
      //         });
      //         $prarent.children("img:nth-child(2)").stop(true).css("opacity","1");
      //     }
      // )
    }

    $(function () {
      menuSlide();
      owlCarousel();
      // spanText();
      scrollReveal();
      hoverImg();
    });
  }
}

window.GApp = new App();
